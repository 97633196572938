import { Form, TabsProps, Typography } from 'antd';
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import CreateTabs from "../../Components/CreateTabs";
import CreateOrgForm from '../../Components/SubPages/CreateOrgForm';
import CreateHardwareDetailForm from '../../Components/SubPages/CreateHardwareDetailForm';
import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION } from '../../Graphql/Mutations';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Error as ErrorType } from '../../Types/MessageType';
import globals from '../../Constants/Globals';
import LoadingButton from '@mui/lab/LoadingButton';
import Message from '../../Components/Message';
import { GET_ORG_BY_ID } from '../../Graphql/Queries';
import { useLocation, useNavigate } from 'react-router-dom';
import { SuperAdminPaths } from '../../Routes/Paths';
import { uploadImage } from '../../Utils/imageUpload'; // Assume this function handles the image upload
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const CreateOrganization = () => {
  const [tab, setTab] = useState<boolean>(true);
  const [selectedKey, setSelectedKey] = useState<string>('1');
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<ErrorType>({ type: 'warning', message: '' });
  const [createOrganization, { loading: loadingOrganization }] = useMutation(CREATE_ORGANIZATION);
  const [updateOrganization, { loading: loadingOrg }] = useMutation(UPDATE_ORGANIZATION);
  const location = useLocation();
  const navigate = useNavigate();
  const [allocatedHardwares, setAllocatedHardwares] = useState<any[]>([]); // Specify type if possible
  const [deviceImages, setDeviceImages] = useState<{ [key: string]: File }>({});
  const [invoiceFiles, setInvoiceFiles] = useState<{ [key: string]: File }>({});
  const [loading, setLoading] = useState(false);

  const [getOrgaDataById] = useLazyQuery(GET_ORG_BY_ID, {
    variables: {
      organizationByIdId: location.state?.orgId
    },
    onCompleted(data) {
      const trainingPackage = data.organizationById.trainingPackage?._id;
      const training = data.organizationById.training?._id;
      const orgData = { ...data.organizationById, trainingPackage, training };

      // Extracting allotedHardwares and setting in state
      const allotedHardwares = data.organizationById.allotedHardwares || [];
      form.setFieldsValue(orgData);
      setAllocatedHardwares(allotedHardwares);
    }
  });

  useEffect(() => {
    if (!!location.state?.orgId) {
      getOrgaDataById();
    }
  }, [location.state?.orgId, getOrgaDataById]);

  // Add this new effect to update allocatedHardwares when form values change
  useEffect(() => {
    const formValues = form.getFieldsValue();
    const updatedHardwares = allocatedHardwares.map((hardware, index) => ({
      ...hardware,
      serialNumber: formValues[`serialNumber-${index}`],
      deviceId: formValues[`deviceId-${index}`],
      deviceModel: formValues[`deviceModel-${index}`],
      source: formValues[`source-${index}`],
      warranty: formValues[`warranty-${index}`],
      purchaseDate: formValues[`purchaseDate-${index}`],
    }));
    setAllocatedHardwares(updatedHardwares);
  }, [form]);

  const updateAllocatedHardwares = useCallback((updatedHardwareList: any[]) => {
    setAllocatedHardwares(updatedHardwareList);
  }, []);

  const items: TabsProps['items'] = [
  {
    key: '1',
    label: <h3>Company Information</h3>,
    children: <CreateOrgForm alertMessage={alert} />
  },
  {
    key: '2',
    label: <h3>Hardware Details</h3>,
    children: <CreateHardwareDetailForm allotedHardwares={allocatedHardwares} organizationDetails={form.getFieldsValue()} updateParentComponent={updateAllocatedHardwares} />
  },
];


const createOrganizationHandler = useCallback(async (value: any) => {
  const formData = form.getFieldsValue();
  
  const payload = {
    _id: location.state?.orgId,
    name: formData.name,
    image: formData.image,
    address: formData.address,
    city: formData.city,
    country: formData.country,
    zip: formData.zip,
    phone: formData.phone,
    adminName: formData.adminName,
    adminEmail: formData.adminEmail,
    alternatePhone: formData.alternatePhone,
    trainingPackage: formData.trainingPackage,
    training: formData.training,
    userLicenseCount: +formData.userLicenseCount,
    allotedHardwares: allocatedHardwares.map(hardware => {
      const { __typename, organization, ...hardwareWithoutTypename } = hardware;
      return {
        ...hardwareWithoutTypename,
        invoice: hardware.invoice || '',
        deviceImage: hardware.deviceImage || '',
      };
    }),
  };

  // Use the same mutation for both create and update
  const mutation = !!location.state?.orgId ? updateOrganization : createOrganization;
  const successMessage = !!location.state?.orgId ? "Organization updated successfully" : "Organization created successfully";

  mutation({
    variables: { org: payload },
    context: {
      hasUpload: true,
    },
    onCompleted() {
      setAlert({ type: "success", message: successMessage });
      form.resetFields();  // Reset form after successful submission
      setTimeout(() => {
        setAlert({ type: 'success', message: '' });
        navigate(SuperAdminPaths.ORGANIZATION_PATH);  // Navigate to organization list
      }, globals.timeout);
    },
    onError(error) {
      setAlert({ type: 'error', message: error.message });
      setTimeout(() => {
        setAlert({ type: 'error', message: '' });
        if (!location.state?.orgId) {
          navigate(SuperAdminPaths.ORGANIZATION_PATH);  // Navigate to organization list on error for create operation
        }
      }, globals.timeout);
    }
  });
}, [form, location.state?.orgId, allocatedHardwares, updateOrganization, createOrganization, navigate]);

  const handleSubmit = async () => {
    setLoading(true);
    const formData = form.getFieldsValue();
    
    // First, upload all new files
    const uploadPromises = [];
    const updatedHardwares = await Promise.all(allocatedHardwares.map(async (hardware) => {
      const updatedHardware = { ...hardware };
      // For date fields, only include if they have a value, otherwise set to null
      updatedHardware.warranty = updatedHardware.warranty || null;
      updatedHardware.purchaseDate = updatedHardware.purchaseDate || null;

      // Handle file uploads
      if (hardware.deviceImage instanceof File) {
        uploadPromises.push(uploadImage(hardware.deviceImage).then(response => {
          updatedHardware.deviceImage = response.imageUrl;
        }));
      } else if (!hardware.deviceImage) {
        delete updatedHardware.deviceImage;
      }

      if (hardware.invoice instanceof File) {
        uploadPromises.push(uploadImage(hardware.invoice).then(response => {
          updatedHardware.invoice = response.imageUrl;
        }));
      } else if (!hardware.invoice) {
        delete updatedHardware.invoice;
      }

      return updatedHardware;
    }));

    // Upload organization image if it's a new file
    if (formData.image instanceof File) {
      uploadPromises.push(uploadImage(formData.image).then(response => {
        formData.image = response.imageUrl;
      }));
    }

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);

    const payload = {
      _id: location.state?.orgId,
      name: formData.name,
      image: formData.image,
      address: formData.address,
      city: formData.city,
      country: formData.country,
      zip: formData.zip,
      phone: formData.phone,
      adminName: formData.adminName,
      adminEmail: formData.adminEmail,
      alternatePhone: formData.alternatePhone || null,
      trainingPackage: formData.trainingPackage,
      training: formData.training,
      userLicenseCount: +formData.userLicenseCount,
      allotedHardwares: updatedHardwares,
    };

    // Use the same mutation for both create and update
    const mutation = !!location.state?.orgId ? updateOrganization : createOrganization;
    const successMessage = !!location.state?.orgId ? "Organization updated successfully" : "Organization created successfully";

    try {
      const { data } = await mutation({ 
        variables: { org: payload },
      });

      setAlert({ type: "success", message: successMessage });
      form.resetFields();  // Reset form after successful submission
      setTimeout(() => {
        setAlert({ type: 'success', message: '' });
        navigate(SuperAdminPaths.ORGANIZATION_PATH);
      }, globals.timeout);
    } catch (error) {
      console.error('Mutation error:', error);
      if (error instanceof Error) {
        setAlert({ type: 'error', message: error.message });
      } else {
        setAlert({ type: 'error', message: 'An unknown error occurred' });
      }
      setTimeout(() => {
        setAlert({ type: 'error', message: '' });
        if (!location.state?.orgId) {
          navigate(SuperAdminPaths.ORGANIZATION_PATH);
        }
      }, globals.timeout);
    } finally {
      setLoading(false);
    }
  };

  // Modify the tab change logic to allow switching back to the first tab
  const handleTabChange = (key: string) => {
    setSelectedKey(key);
    setTab(key === '2'); // This will allow you to switch back to the first tab
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        form={form}
        layout={'vertical'}
        onValuesChange={() => {
          // Force re-render to update allocatedHardwares
          setAllocatedHardwares([...allocatedHardwares]);
        }}
      >
        <Box sx={{ mb: 3 }} display={'flex'} justifyContent={'flex-end'}>
          <Box flexGrow={1} sx={{ pr: 3 }}>
            {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
          </Box>
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            sx={{ px: 5, py: 1, color: 'white', height: '45px' }}
          >
            {location.state?.orgId ? 'Update' : 'Save'}
          </LoadingButton>
        </Box>
        <CreateTabs 
          items={items} 
          heading={'Organization Details'} 
          headingStyle={{ color: 'black' }} 
          keySelected={selectedKey} 
          defaultKey={'1'} 
          selectedTab={(isSelected: boolean) => handleTabChange(isSelected ? '2' : '1')}
        />
      </Form>
    </>
  );
};

export default CreateOrganization;
