import { useQuery } from "@apollo/client";
import { Button, Typography, Box } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../Components/Cards/Card";
import DataTable from "../../Components/Datatable";
import Stats from "../../Components/Stats";
import TableHeading from "../../Components/TableHeading";
import globals from "../../Constants/Globals";
import { GET_ALL_ORGANIZATIONS } from "../../Graphql/Queries";
import { SuperAdminPaths } from "../../Routes/Paths";
import { pages } from "../../Types/DatatableType";
import { DELETE_ORGANIZATION } from "../../Graphql/Mutations";
import { useMutation } from "@apollo/client";
import { Error } from '../../Types/MessageType';
import Message from '../../Components/Message';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Organization = () => {
  const [searchCriteria, setSearchCriteria] = useState({
    name: '',
    adminName: '',
    trainingModule: ''
  });
  const handleSearchChange = (field: string, value: string) => {
    setSearchCriteria(prev => ({
      ...prev,
      [field]: value
    }));
  };
 const { CREATE_ORGANIZATION_PATH } = SuperAdminPaths
 const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });
 const [tableData, setTableData] = useState<any[]>([]);
 const [totalPages, setTotalPages] = useState<pages>()
 const [currentPage, setCurrentPage] = useState<number>(1)
 const [orgCount,setOrgCount]=useState<number>(0);
 const { loading: organizationsLoading } = useQuery<any>(GET_ALL_ORGANIZATIONS, {
  variables: {
   page: currentPage,
   limit: globals.pageSize,
   search: searchCriteria
  },
  onCompleted(data) {
   if (data.allOrganizations.organizations.length > 0) {
    const org = data?.allOrganizations?.organizations;
    setOrgCount(data?.allOrganizations?.totalDocs);
    setTotalPages({
     currentPage: data?.allOrganizations?.currentPage,
     limit: globals.pageSize,
     totalDocs: data.allOrganizations?.totalDocs,
     totalPages: data.allOrganizations?.totalPages,
    })
    setTableData([]);
    if (org.length > 0) {
     const _org = org.map((_singleOrg: any, index: number) => {
      return {
       key: index,
       orgId: _singleOrg._id,
       orgName: _singleOrg.name,
       orgAdmin: _singleOrg.name,
       trainingModule: _singleOrg.trainingPackage ? _singleOrg.trainingPackage.name : "",
       trainingModuleId: _singleOrg.trainingPackage ? _singleOrg.trainingPackage._id : "",
      }
     })
     setTableData(_org)
    }
   }
  }
 })

 
 const [deleteOrganization] = useMutation(DELETE_ORGANIZATION);
 const [open, setOpen] = useState(false);
 const [record,setRecord]=useState(null);

 const handleClickOpen = (record:any) => {
  setRecord(record);
   setOpen(true);
 };

 const handleDelete = () => {
   handleDeleteOrganization(record);
   setOpen(false);
   setRecord(null);
 };

 const handleClose = ()=>{
  setOpen(false);
 }

 const handleDeleteOrganization = (record: any): void => {
    deleteOrganization({
        variables:{
          id:record.orgId
        },
        onCompleted(data) {
            const updatedTableData = tableData.filter(item => item.orgId !== record.orgId);
            setTableData(updatedTableData);
            setOrgCount(orgCount=>orgCount-1);
            setAlert({ type: "success", message: "Organization deleted successfully" })
            setTimeout(() => {
              setAlert({
                type: 'success',
                message: ''
              })
            }, globals.timeout)
          },
          onError(error) {
            setAlert({ type: 'error', message: error.message })
            setTimeout(() => {
              setAlert({
                type: 'error',
                message: ''
              })
            }, globals.timeout);
          }
    })
 }

 const columns: ColumnsType<any> = [
  {
   title: (
    <TableHeading heading="Organization Name" setSearch={(value) => handleSearchChange('name', value)} setPage={setCurrentPage}/>
   ),
   dataIndex: "orgName",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
   key: "1",
  },
  {
   title: (
    <TableHeading heading="Organization Admin" setSearch={(value) => handleSearchChange('adminName', value)} setPage={setCurrentPage}/>
   ),
   dataIndex: "orgAdmin",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
   key: "2",
  },
  {
   title: (
    <TableHeading heading="Training Module" setSearch={(value) => handleSearchChange('trainingModule', value)} setPage={setCurrentPage}/>
   ),
   dataIndex: "trainingModule",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
   key: "3",
  },
  {
   title: '',
   render: (_, record) => <Button variant="contained" sx={{ alignSelf: 'flex-end' }}><Link style={{ textDecoration: 'none', color: 'white' }} to={record.orgId} state={{ orgId: record.orgId }}>View Details</Link></Button>,
   key: "4",
   width: "16%"
  },
  {
    title: '',
    render: (_, record) => <IconButton
                              sx={{ alignSelf: 'flex-end' }}
                              onClick={() => handleClickOpen(record)}>
                                  <DeleteIcon />
                            </IconButton>,
    key: "5",
    width: "5%"
   }
 ]

 return (
  <>
   <Stats buttonText="Create Organization" text="Total Organizations" number={orgCount} link={CREATE_ORGANIZATION_PATH} />

   <Box flexGrow={1} sx={{ pr: 3 }}>
      {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
   </Box>
   <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose()}>Cancel</Button>
          <Button onClick={()=>handleDelete()} autoFocus>
            Confirm
          </Button>
        </DialogActions>
    </Dialog>

   <Card>
    <DataTable
     loading={organizationsLoading}
     tableData={tableData}
     totalPages={totalPages}
     showCheck={false}
     columnData={columns}
     page={(cp) => {
      setCurrentPage(Number(cp))
     }}
     scrollX="max-content"
     scrollY={450}
    />
   </Card>
  </>
 )

}

export default Organization;