import { useMutation, useQuery } from "@apollo/client";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Form, FormInstance } from "antd";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import globals from "../../Constants/Globals";
import { RoleCodes } from "../../Constants/RoleCode";
import { CREATE_USER, UPDATE_USER } from "../../Graphql/Mutations";
import { Error } from "../../Types/MessageType";
import { permissionValuesArr } from "../../Types/SelectType";
import FormItemInput from "../FormItems/FormItemInput";
import FormItemSelect from "../FormItems/FormItemSelect";
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ORGANIZATIONS, GET_USER_BY_ID } from '../../Graphql/Queries';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { SuperAdminPermissions } from "../../Constants/Permissions";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

type createUserProp = {
  disabledEv: (val: boolean) => void;
  alertMessage: (val: Error) => void;
};

const options: permissionValuesArr[] = [
  {
    label: 'Company Admin',
    value: RoleCodes.COMPANY_ADMIN,
    permission:SuperAdminPermissions.CREATE_USER_COMPANY_ADMIN
  },
  {
    label: 'Company Manager',
    value: RoleCodes.COMPANY_MANAGER,
    permission:SuperAdminPermissions.CREATE_USER_COMPANY_MANAGER
  },
  {
    label: 'Company User',
    value: RoleCodes.COMPANY_USER,
    permission:SuperAdminPermissions.CREATE_USER_COMPANY_USER
  }
];


const CreateUserForm = ({ disabledEv, alertMessage }: createUserProp, ref: any) => {

  const [form] = Form.useForm();
  const submitRef = useRef<FormInstance<any>>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const { organization, permissions } = useSelector((state: any) => state.auth);
  const [userDataState, setUserData] = useState<any>({});
  const [createUser, { loading: creatingUser }] = useMutation(CREATE_USER);
  const [updateUserHandler, { loading: updateUser }] = useMutation(UPDATE_USER);

  useImperativeHandle(ref, () => ({
    inputRef: inputRef,
    submit: () => form.submit()
  }));

  const { data } = useQuery<any>(GET_ALL_ORGANIZATIONS, {
    variables:{
      page:1,
      limit:30
    },
    skip: !permissions.includes(SuperAdminPermissions.VIEW_ORGANIZATION)
  })

  const updatedOptions: permissionValuesArr[]=options.filter((option)=>permissions.includes(option.permission))

  let organizationsOptions: { label: any; value: any; }[]=[];

  data?.allOrganizations?.organizations.map((org: { name: any; _id: any; })=>{
      organizationsOptions.push({
       label:org.name,
       value:org._id
      })
   });

  const [getUserById] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted(data) {
      if (!!data.userWithRolesById) {
        form.setFieldValue('roleCode', data.userWithRolesById.roles[0].roleName);
        form.setFieldsValue(data.userWithRolesById);
        setUserData((fetchedData: any) => {
          return { ...fetchedData, oldRoleCode: data.userWithRolesById.roles[0].roleCode };
        });
      }
    }
  });

  useEffect(() => {
    if (!!location.state?.userId) {
      getUserById(
        {
          variables: {
            userWithRolesByIdId: location.state?.userId
          }
        });
    }
  }, [location.state?.userId, getUserById]);

  const createUserHandler = () => {
    const userData = form.getFieldsValue();

    if (!!location.state?.userId) {
      delete userData['roleCode'];
      updateUserHandler({
        variables: {
          userInput: { ...userDataState, ...userData, newRoleCode: form.getFieldValue('roleCode'), _id: location.state?.userId, organization:data?form.getFieldValue('organization'): organization }
        },
        onCompleted() {
          alertMessage({ type: "success", message: "User updated successfully" });
          form.resetFields();
          setTimeout(() => {
            alertMessage({
              type: 'success',
              message: ''
            });
          }, globals.timeout);
        },
        onError(error) {
          alertMessage({ type: 'error', message: error.message });
          setTimeout(() => {
            alertMessage({
              type: 'success',
              message: ''
            });
          }, globals.timeout);
        }
      });
    } else {
      createUser({
        variables: {
          userInput: { ...userData,organization:data? form.getFieldValue('organization'): organization }
        },
        onCompleted() {
          alertMessage({ type: "success", message: "User created successfully" });
          form.resetFields();
          setTimeout(() => {
            alertMessage({
              type: 'success',
              message: ''
            });
          }, globals.timeout);
        },
        onError(error) {
          alertMessage({ type: 'error', message: error.message });
          setTimeout(() => {
            alertMessage({
              type: 'error',
              message: ''
            });
          }, globals.timeout);
        }
      });
    }
  };

  useEffect(() => {
    disabledEv(location.state?.userId ? updateUser : creatingUser);
  }, [creatingUser, location.state?.userId, updateUser, disabledEv]);

  const [phone, setPhone] = useState("");
  
  const extractLocalNumber = (phone: string | null | undefined) => {
    if (!phone) {
      return ""; 
    }
    // Remove any non-digit characters and spaces
    const digitsOnly = phone.replace(/\D/g, '');
    if (digitsOnly.length > 10) {
      return digitsOnly.slice(-10); 
    }
    return digitsOnly; 
  };
  
  const validatePhoneNumber = (phone: string | null | undefined) => {
    if (!phone) {
      return false; 
    }
    const localNumber = extractLocalNumber(phone);
    return localNumber.length === 10;
  };
  
  const handleValue = (value: string | null | undefined) => {
    setPhone(value || ""); 
    const isValid = validatePhoneNumber(value);
    if (!isValid) {
      console.error("Phone number must be 10 digits");
    }
  };
  

  return (
    <>
      <Form
        ref={submitRef}
        onFinish={createUserHandler}
        form={form}
        layout={'vertical'}
        style={{ padding: '0 2%' }}
      >
        <Grid2 container spacing={2} >
          <Grid2 xs={12} sm={6}>
            <FormItemInput label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]} type={'text'} />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <FormItemInput label="Gender" name="gender" rules={[{ required: true, message: 'Gender is required' }]} type={'radio'} />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <FormItemInput label="Email Id" name="email" rules={[{ required: true, message: 'Email is required' }, { type: 'email', message: 'Email format is incorrect' }]} type={'email'} />
          </Grid2>
          <Grid2 xs={12} sm={6}>
          <FormItemInput
            label="Phone Number"
            name="phone"
            rules={[
              {required: true, message: 'Phone number is required',
                validator: (_, value) => {
                  if (validatePhoneNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject({
                    type: 'error',
                    message: 'Phone number must be 10 digits',
                    variant: 'outlined', 
                  });
                },
              },
            ]}
            type="text"
          >
            <PhoneInput
              placeholder="Enter phone number"
              value={phone}
              onChange={handleValue}
              international
            />
          </FormItemInput>          
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <FormItemSelect label="Role" name="roleCode" rules={[{ required: true, message: 'Role is required' }]} value={updatedOptions} setValue={(v) => null} />
          </Grid2>
          {data?<Grid2 xs={12} sm={6}>
            <FormItemSelect label="Organization" name="organization" rules={[{ required: true, message: 'Organization is required' }]} value={organizationsOptions} setValue={(v) => null} />
          </Grid2>:null}
        </Grid2>
      </Form>
    </>
  );
};

export default React.forwardRef(CreateUserForm);