import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { GET_ORG_BY_ID, GET_USER_BY_ID, GET_TRAINING_MODULE__BY_ID, GET_TRAINING_BY_ID } from '../Graphql/Queries';

export default function BasicBreadcrumbs() {
 const location = useLocation();
 const [paths, setPaths] = useState<string[]>([]);
 const [breadcrumbNames, setBreadcrumbNames] = useState<Record<string, string | null>>({});
 const theme = useTheme();

 // Helper function to capitalize the first letter
 const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

 // Lazy queries for fetching names
 const [getOrgaDataById] = useLazyQuery(GET_ORG_BY_ID, {
   onCompleted: (data) => {
     if (data && data.organizationById) {
       setBreadcrumbNames(prev => ({ ...prev, [location.state?.orgId]: data.organizationById.name }));
     }
   }
 });
 const [getUserDataById] = useLazyQuery(GET_USER_BY_ID, {
   onCompleted: (data) => {
     if (data && data.userWithRolesById) {
       setBreadcrumbNames(prev => ({ ...prev, [location.state?.userId]: data.userWithRolesById.name }));
     }
   }
 });
 const [getTrainingModuleDataById] = useLazyQuery(GET_TRAINING_MODULE__BY_ID, {
   onCompleted: (data) => {
     if (data && data.trainingPackageById) {
       setBreadcrumbNames(prev => ({ ...prev, [location.state?.trainingPackageId]: data.trainingPackageById.name }));
     }
   }
 });
 const [getTrainingDataById] = useLazyQuery(GET_TRAINING_BY_ID, {
   onCompleted: (data) => {
     if (data && data.trainingById) {
       setBreadcrumbNames(prev => ({ ...prev, [location.state?.trainingId]: data.trainingById.name }));
     }
   }
 });

 useEffect(() => {
  const pathSegments = location.pathname.split('/').filter(Boolean);
  setPaths(pathSegments);

  pathSegments.forEach((path, index) => {
    if (pathSegments[index - 1] === 'organizations' && location.state?.orgId) {
      getOrgaDataById({ variables: { organizationByIdId: location.state.orgId } });
    } 
    else if (pathSegments[index - 1] === 'users' && location.state?.userId) {
      getUserDataById({ variables: { userWithRolesByIdId: location.state.userId } });
    } 
    else if (pathSegments[index - 1] === 'training-package' && location.state?.trainingPackageId) {
      getTrainingModuleDataById({ variables: { trainingPackageByIdId: location.state.trainingPackageId } });
    } 
    else if (pathSegments[index - 1] === 'trainings' && location.state?.trainingId) {
      getTrainingDataById({ variables: { trainingByIdId: location.state.trainingId } });
    } 
    else {
      setBreadcrumbNames(prev => ({ ...prev, [path]: capitalizeFirstLetter(path) }));
    }
  });
 }, [location.pathname]);

 return (
  <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ color: theme.status.white }}>
   {paths.map((path, index) => {
    const displayText = breadcrumbNames[path] ? capitalizeFirstLetter(breadcrumbNames[path]!) : capitalizeFirstLetter(path);
    const isLast = index === paths.length - 1;

    return isLast ? (
     <Typography key={path} variant="body1">
      {displayText}
     </Typography>
    ) : (
     <Link key={path} to={`/${paths.slice(0, index + 1).join('/')}`} style={{ color: theme.status.white, textDecoration: 'none' }}>
      {displayText}
     </Link>
    );
   })}
  </Breadcrumbs>
 );
}
