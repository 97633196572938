import { Form, Input, Radio } from "antd";
import { Rule } from "antd/es/form";

type formItemProp = {
  label: string;
  name?: string;
  rules?: Rule[];
  type: string;
  value?: string;
  onChange?: (e: any) => void;
  readOnly?: boolean;
  children?: React.ReactNode;
};

const FormItemInput = ({ label, name, rules, type, value, onChange, readOnly, children }: formItemProp) => {
  return (
      <Form.Item label={label} name={name} rules={rules} initialValue={value}>
        {children || (type === "radio" ? (
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={"male"}>Male</Radio>
            <Radio value={"female"}>Female</Radio>
          </Radio.Group>
        ) : (
          <Input
            type={type}
            value={readOnly ? value : undefined}
            onChange={readOnly ? undefined : onChange}
            readOnly={readOnly}
          />
        ))}
      </Form.Item>
  );
};

export default FormItemInput;
