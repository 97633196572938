export enum IQTraining {
  NCLEX_MODULE = "NCLEX Module",
  DRAIN_CARE = "Drain Care",
  CPR = "CPR",
  BLOOD_PRESSURE_TRAINING = "Blood pressure training",
  BREATHING_EXERCISE = "Breathing exercise",
  DENTAL_IMPLANT = "Dental Implant",
  ECG_TRAINING = "ECG Training",
  HAND_WASH_TRAINING = "Hand Wash Training",
  INJECTION_TRAINING = "Injection Training",
  INSERT_IV = "Insert IV",
  INTRAVENOUS = "Intravenous",
  INTUBATION = "Intubation",
  INTUBATION_TRAINING = "Intubation training",
  SEPSIS = "Sepsis",
  ULTRASOUND = "Ultra sound",
  VITAL_SIGNS = "Vital signs",
} 