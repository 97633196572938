import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState } from "react";
import { Error } from "../../Types/MessageType";
import FormItemInput from "../FormItems/FormItemInput";
import UploadImage from "../FormItems/UploadImage";
import TrainingModule from "../InputRequest/TrainingModule";
import Training from "../InputRequest/Training";
import Trainings from "../../Pages/SuperAdmin/Trainings";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

type createOrgProp = {
 alertMessage: Error;
};

const CreateOrgForm = ({ alertMessage }: createOrgProp) => {
const [phone, setPhone] = useState("");

const extractLocalNumber = (phone: string | null | undefined) => {
  if (!phone) {
    return ""; 
  }
  // Remove any non-digit characters and spaces
  const digitsOnly = phone.replace(/\D/g, '');
  if (digitsOnly.length > 10) {
    return digitsOnly.slice(-10); 
  }
  return digitsOnly; 
};

const validatePhoneNumber = (phone: string | null | undefined) => {
  if (!phone) {
    return false; 
  }
  const localNumber = extractLocalNumber(phone);
  return localNumber.length === 10;
};

const handleValue = (value: string | null | undefined) => {
  setPhone(value || ""); 
  const isValid = validatePhoneNumber(value);
  if (!isValid) {
    console.error("Phone number must be 10 digits");
  }
};

 return (
  <>
   <Grid2 container spacing={2} paddingX={3}>
    <Grid2 xs={12} sm={6}>
     <UploadImage name={'image'} label={'Upload Organization Image'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Organization Name" name="name" rules={[{ required: true, message: 'Organization is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Address" name="address" rules={[{ required: true, message: 'Address is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="City" name="city" rules={[{ required: true, message: 'City is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Country" name="country" rules={[{ required: true, message: 'COuntry is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
      <FormItemInput label="Zip" name="zip" rules={[{ required: true, message: 'Zip is required' }, { pattern: /^[0-9]{4,6}$/, message: "ZIP code must be 4 to 6 digits" },]} type={'text'} />
     </Grid2>
     <Grid2 xs={12} sm={6}>
     <FormItemInput
    label="Phone Number"
    name="phone"
    rules={[
      {required: true, message: 'Phone number is required',
        validator: (_, value) => {
          if (validatePhoneNumber(value)) {
            return Promise.resolve();
          }
          return Promise.reject({
            type: 'error',
            message: 'Phone number must be 10 digits',
            variant: 'outlined', 
          });
        },
      },
    ]}
    type="text"
  >
    <PhoneInput
      placeholder="Enter phone number"
      value={phone}
      onChange={handleValue}
      international
    />
  </FormItemInput>
     </Grid2>
    <Grid2 xs={12} sm={6}>
    <FormItemInput
    label="Alternate Number"
    name="alternatePhone"
    rules={[
      {
        validator: (_, value) => {
          if (!value) {
            return Promise.resolve();
          }
          if (validatePhoneNumber(value)) {
            return Promise.resolve();
          }
          return Promise.reject({
            type: 'error',
            message: 'Phone number must be 10 digits',
            variant: 'outlined', 
          });
        },
      },
    ]}
    type="text"
  >
    <PhoneInput
      placeholder="Enter phone number"
      value={phone}
      onChange={handleValue}
      international
    />
  </FormItemInput>    
  </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Organization Admin Name" name="adminName" rules={[{ required: true, message: 'Organization is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Organization Admin Email Id" name="adminEmail" rules={[{ required: true, message: 'Email is required' }, { type: 'email', message: 'Email format is incorrect' }]} type={'email'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <TrainingModule name="trainingPackage" label="Training Module" />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <Training name="training" label="Training" />
    </Grid2>
   </Grid2>
  </>
 );
};

export default CreateOrgForm;